
const fetchAPI = async (url, method = 'GET', body = null, headers = {}) => {

    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
            ...headers
        }
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    const response = await fetch(url, options);

    if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();

    if (!data.success) {
        throw new Error(data.message);
    }

    return data;
};

export default fetchAPI;