import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getToken } from '../../../helpers/token';
import { urlAPIMultiAgent } from '../../../helpers/urlAPI';
import fetchAPI from '../../../helpers/fetch';
import { v4 as uuidv4 } from 'uuid';
import { getLocalStorageItem, setLocalStorageItem } from '../../../helpers/localStorage';

function DropdownProfile() {

	const userSession = JSON.parse(getLocalStorageItem("usr"));
	const { suscriptionId } = getLocalStorageItem("suscription") !== null ? JSON.parse(getLocalStorageItem("suscription")) : { suscriptionId: 0 };
	const navigate = useNavigate();
	const [redirect, setRedirect] = useState(false);
	const [dataSuscriptions, setDataSuscriptions] = useState([]);

	const handleLogOut = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('usr');
		localStorage.removeItem('suscription');
		setRedirect(true);
	}

	const handleReload = (id) => {
		handleSwitchSuscription(id);
		navigate(0);
	};

	const handleSwitchSuscription = (id) => {
		setLocalStorageItem("suscription", JSON.stringify({ suscriptionId: id }))
	}


	useEffect(() => {

		const fetchDataUser = async () => {
			const headers = {
				'Authorization': `Bearer ${getToken()}`
			}
			//Cuando no hay una sesión iniciada
			if (getToken() === "") {
				return;
			}

			const result = await fetchAPI(`${urlAPIMultiAgent}/user/${userSession.userId}/get`, 'POST', null, headers);
			const { suscriptions } = result.data;

			//poner por defecto el primer registro al iniciar sesión
			if (suscriptionId === 0) {
				setLocalStorageItem("suscription", JSON.stringify({ suscriptionId: suscriptions[0].id, suscriptionCode: suscriptions[0].code }))
			}
			setDataSuscriptions(suscriptions);
		}

		fetchDataUser();
		//   return () => {
		//   }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (redirect) {
		return <Navigate to='/user/login-v3' replace={true} />;
	}
	return (
		<div className="navbar-item navbar-user dropdown">
			<a href="#/" className="navbar-link dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
				<img src="/assets/img/user/user-13.jpg" alt="" />
				<span>
					<span className="d-none d-md-inline">Adam Schwartz</span>
					<b className="caret"></b>
				</span>
			</a>
			<div className="dropdown-menu dropdown-menu-end me-1">
				{/* <a href="#/" className="dropdown-item">Edit Profile</a>
				<a href="#/" className="dropdown-item d-flex align-items-center">
					Inbox
					<span className="badge bg-danger rounded-pill ms-auto pb-4px">2</span>
				</a>
				*/}
				{
					(Array.isArray(dataSuscriptions) && dataSuscriptions.length > 0) && dataSuscriptions.map(({ code, id }) => (
						<button key={uuidv4()} onClick={() => handleReload(id)} className={`dropdown-item ${suscriptionId === id && 'active'}`}>Suscripción {id}</button>
					))
				}
				<button className="dropdown-item">Perfil</button>
				<button className="dropdown-item">Ajustes</button>
				<div className="dropdown-divider"></div>
				<button onClick={() => handleLogOut()} className="dropdown-item">Cerrar Sesión</button>
			</div>
		</div>
	);
};

export default DropdownProfile;
