import React from "react";
import PropTypes from 'prop-types';

const Modal = ({ header, body, btnTextAccept = "Aceptar", btnTextCancel = "Cancelar", idModal, showFooter = false, handleCloseModal, size = "", handleButtonAccept = () => { } }) => {

    return (

        <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" id={idModal}>
            <div className={`modal-dialog ${size}`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4>{header}</h4>
                        <button type="button" onClick={handleCloseModal} className="btn-close"></button>
                    </div>
                    <div className="modal-body">
                        {body}
                    </div>
                    {
                        showFooter && <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-bs-dismiss={"modal"}>{btnTextCancel}</button>
                            <button type="button" className="btn btn-theme" onClick={() => handleButtonAccept()}>{btnTextAccept}</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    idModal: PropTypes.string.isRequired,
};
export default Modal;