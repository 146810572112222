import { Navigate } from 'react-router-dom';
import { getLocalStorageItem } from '../helpers/localStorage';

const PrivateRoute = ({ children }) => {

    const isAuthenticated = getLocalStorageItem("token") !== null;

    return isAuthenticated ? children : <Navigate to="/user/login-v3" />;
};

export default PrivateRoute;