import React, { useEffect, useContext, useState, useRef } from 'react';
import { AppSettings } from '../../config/app-settings.js';
import { Icon } from '@iconify/react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MessageContainer from '../../ownComponents/ChatContainer/messageContainer.js';
import Chat from '../../ownComponents/ChatContainer/chat.js';
import { v4 as uuidv4 } from 'uuid';
import EmojiPickerComponent from '../../ownComponents/emojiPicker/index.js';
import { getLocalStorageItem } from "../../helpers/localStorage";
import { getToken } from "../../helpers/token";
import { urlAPIMultiAgent } from "../../helpers/urlAPI";
import fetchAPI from "../../helpers/fetch";
import { regexTextWithoutSpace } from '../../helpers/regex.js';
import Alert, { addNotification } from "../../ownComponents/alerts/alerts"
import { handleErrorFormat } from '../../helpers/handleError.js';
import { Modal as ModalFileManager } from 'bootstrap';
import ModalFiles from './modalFiles.js';
// import "yet-another-react-lightbox/styles.css";
function Conversations() {
	const token = getToken();
	const { suscriptionId, suscriptionCode } = JSON.parse(getLocalStorageItem("suscription"));
	const context = useContext(AppSettings);
	const [isMobileSidebarToggled, setIsMobileSidebarToggled] = useState(false);
	const [selectedChat, setSelectedChat] = useState({
		id: 0,
		agent: "",
		client: "",
		startDate: "",
		endDate: "",
		active: false,
		waitingForAgent: false,
		waitingForFile: null,
		startedByAgent: false,
		finishedByAgent: null
	});

	const [textArea, setTextArea] = useState("");
	const [reloadData, setReloadData] = useState(false);
	const [modalFiles, setModalFiles] = useState();
	const [dataFileId, setDataFileId] = useState(0);

	const textareaRef = useRef(null);

	const handleEmojiSelect = (emojiObject) => {
		setTextArea(textArea + emojiObject.emoji)
	};
	const [dataConversations, setDataConversations] = useState([]);
	const [dataConversation, setDataConversation] = useState([]);

	const [dataFilterInfoDemo, setDataFilterInfoDemo] = useState([]);

	const handleMobileSidebarToggle = () => {
		setIsMobileSidebarToggled(!isMobileSidebarToggled);
	};

	const handleSearch = (event) => {
		const { target: { value } } = event;

		if (event.key === 'Enter') {
			const result = dataConversations.filter(item => item.agent.name.toLowerCase().includes(value.toLowerCase()))
			setDataFilterInfoDemo(result);
		}
	}

	const handleOpenModalFiles = () => {
		modalFiles.show();
		setDataFileId(0);
	}

	useEffect(() => {
		// context.handleSetAppSidebarMinified(true);
		// context.handleSetAppHeaderInverse(true);
		context.handleSetAppContentFullHeight(true);
		context.handleSetAppContentClass('p-0');
		setModalFiles(new ModalFileManager(document.getElementById('fileManager')));

		return () => {
			// context.handleSetAppSidebarMinified(false);
			// context.handleSetAppHeaderInverse(false);
			context.handleSetAppContentFullHeight(false);
			context.handleSetAppContentClass('');
		};

		// eslint-disable-next-line
	}, []);

	useEffect(() => {

		const fetchConversations = async () => {
			const headers = {
				'Authorization': `Bearer ${token}`
			}
			const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/conversation/list`, 'POST', null, headers);
			setDataConversations(result.data);
			setDataFilterInfoDemo(result.data);
			if (selectedChat.id === 0) {
				setSelectedChat({
					...selectedChat, client: result.data[0].client?.name, id: result.data[0].id
				})
			}
		}

		fetchConversations();

		// eslint-disable-next-line
	}, []);

	useEffect(() => {

		if (selectedChat.id === 0) {
			return;
		}
		const fetchConversation = async () => {
			const headers = {
				'Authorization': `Bearer ${token}`
			}
			const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/conversation/${selectedChat.id}/message/list`, 'POST', null, headers);
			setDataConversation(result.data);
			if (textareaRef.current) {
				textareaRef.current.focus(); // Poner el foco en el textarea
			}
			setTextArea("");
		}

		fetchConversation();

		// eslint-disable-next-line
	}, [selectedChat.id, reloadData]);



	const handleSendMessage = async (event) => {

		try {
			if (event.key === 'Enter') {
				event.preventDefault();
				if (!regexTextWithoutSpace(textArea)) {
					return;
				}
				await ftFetchSendMessage();

			}
		} catch (error) {
			addNotification({
				notificationTitle: "Ha ocurrido un error",
				notificationMessage: handleErrorFormat(error),
				notificationPosition: 'top-center',
				notificationType: 'warning'
			});
		}

	}

	const handleSendMessageBtn = async (event) => {
		try {
			event.preventDefault();
			if (!regexTextWithoutSpace(textArea)) {
				return;
			}
			await ftFetchSendMessage();
			setReloadData(reloadData => !reloadData);
			setTextArea("");
		} catch (error) {
			addNotification({
				notificationTitle: "Ha ocurrido un error",
				notificationMessage: handleErrorFormat(error),
				notificationPosition: 'top-center',
				notificationType: 'warning'
			});
		}

	}
	const ftFetchSendMessage = async (fileId) => {
		const headers = {
			'Authorization': `Bearer ${token}`
		}
		const bodyFetch = {
			clientId: selectedChat.client.id,
			content: textArea,
			fileId: fileId || dataFileId
		}
		const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/conversation/${selectedChat.id}/message/create`, 'POST', bodyFetch, headers);
		setReloadData(reloadData => !reloadData);
		setTextArea("");
		return result;
	}
	return (
		<div className={'messenger ' + (isMobileSidebarToggled ? 'messenger-chat-content-mobile-toggled' : '')} id="messenger">
			<Alert />
			{/* 
			<div className="messenger-menu">
				<div className="messenger-menu-item my-2">
					<a href="#/" className="messenger-menu-link">
						<div className="m-n1">
							<img alt="" src="/assets/img/user/user-13.jpg" className="w-100 d-block rounded-circle" />
						</div>
					</a>
				</div>
				<div className="messenger-menu-item active">
					<a href="#/" className="messenger-menu-link">
						<Icon className="iconify fs-30px" icon="solar:dialog-2-bold-duotone" />
					</a>
				</div>
				<div className="messenger-menu-item">
					<a href="#/" className="messenger-menu-link">
						<Icon className="iconify fs-30px" icon="solar:notebook-bold-duotone" />
					</a>
				</div>
				<div className="messenger-menu-item">
					<a href="#/" className="messenger-menu-link">
						<Icon className="iconify fs-30px" icon="solar:box-minimalistic-bold-duotone" />
					</a>
				</div>
				<div className="messenger-menu-item">
					<a href="#/" className="messenger-menu-link">
						<Icon className="iconify fs-30px" icon="solar:folder-with-files-bold-duotone" />
					</a>
				</div>
				<div className="messenger-menu-item">
					<a href="#/" className="messenger-menu-link">
						<Icon className="iconify fs-30px" icon="solar:clapperboard-play-bold-duotone" />
					</a>
				</div>
				<div className="messenger-menu-item">
					<a href="#/" className="messenger-menu-link">
						<Icon className="iconify fs-30px" icon="solar:settings-bold-duotone" />
					</a>
				</div>
			</div> */}
			<div className="messenger-chat">
				<div className="messenger-chat-header d-flex">
					<div className="flex-1 position-relative">
						<input type="text" className="form-control border-0 bg-light ps-30px" placeholder="Buscar" onKeyUp={handleSearch} />
						<i className="fa fa-search position-absolute start-0 top-0 h-100 ps-2 ms-3px d-flex align-items-center justify-content-center"></i>
					</div>
					<div className="ps-2">
						<a href="#/" className="btn border-0 bg-light shadow-none">
							<i className="fa fa-plus"></i>
						</a>
					</div>
				</div>
				<div className="messenger-chat-body">
					<PerfectScrollbar className="h-100">
						{
							dataFilterInfoDemo.map((data) => (
								<MessageContainer conversation={data} isActive={selectedChat.id === data.id} ftClick={() => {
									setSelectedChat(data);
								}}
									key={data.id} />
							))
						}
					</PerfectScrollbar>
				</div>
			</div>
			<div className="messenger-content">
				<div className="widget-chat">
					<div className="widget-chat-header">
						<div className="d-block d-lg-none">
							<button type="button" className="btn border-0 shadow-none" onClick={handleMobileSidebarToggle}>
								<i className="fa fa-chevron-left fa-lg"></i>
							</button>
						</div>
						<div className="widget-chat-header-content d-flex align-items-center justify-content-between">
							<div className="fs-5 fw-bold">{selectedChat.client?.name}</div>
							<div className="fs-5 fw-bold">Conversación activa</div>
							<button className="fs-5 fw-bold" onClick={() => alert("Endpoint Pendiente")}>Finalizar conversación</button>
						</div>
						<div className="">
							<button type="button" className="btn border-0 shadow-none" data-bs-toggle="dropdown">
								<i className="fa fa-ellipsis fa-lg"></i>
							</button>
							<ul className="dropdown-menu">
								<li>
									<a className="dropdown-item" href="#/">Action</a>
								</li>
								<li>
									<a className="dropdown-item" href="#/">Another action</a>
								</li>
								<li>
									<a className="dropdown-item" href="#/">Something else here</a>
								</li>
							</ul>
						</div>
					</div>
					<PerfectScrollbar className="widget-chat-body h-100">
						{
							dataConversation.length > 0 ? dataConversation.map((data) => (
								<Chat data={data} key={uuidv4()} suscriptionCode={suscriptionCode} />
							))
								:
								<div className='d-flex align-items-center justify-content-center'>
									<p className='fs-1'>No hay mensajes para mostrar</p>
								</div>
						}
					</PerfectScrollbar>
					<div className="widget-chat-input">
						<div className="widget-chat-toolbar">
							<div className="widget-chat-toolbar-link cursor-pointer">
								<EmojiPickerComponent onEmojiSelect={handleEmojiSelect} />
							</div>
							<div className="widget-chat-toolbar-link cursor-pointer" onClick={() => handleOpenModalFiles()}>
								<Icon className="iconify fs-26px" icon="solar:folder-with-files-outline" />
							</div>
							{/* <a href="#/" className="widget-chat-toolbar-link">
								<Icon className="iconify fs-26px" icon="solar:scissors-square-outline" />
							</a>
							<a href="#/" className="widget-chat-toolbar-link">
								<Icon className="iconify fs-26px" icon="solar:chat-round-dots-outline" />
							</a>
							<a href="#/" className="widget-chat-toolbar-link ms-auto">
								<Icon className="iconify fs-26px" icon="solar:phone-calling-outline" />
							</a>
							<a href="#/" className="widget-chat-toolbar-link">
								<Icon className="iconify fs-26px" icon="solar:videocamera-record-outline" />
							</a> */}
						</div>
						<div className='d-flex'>
							<textarea className="form-control textarea-custom" required placeholder='Escribir mensaje' value={textArea} onChange={(e) => setTextArea(e.target.value)} onKeyDown={handleSendMessage} ref={textareaRef}></textarea>
							<button className='btn btn-primary' onClick={handleSendMessageBtn}>Enviar</button>
						</div>
					</div>
				</div>
			</div>
			<ModalFiles modalFiles={modalFiles} suscriptionId={suscriptionId} handleSendFile={ftFetchSendMessage} />
			{/* <Lightbox
				open={openLightBox}
				close={() => setOpenLightbox(false)}
				slides={[
					{
						src: "https://media.es.wired.com/photos/650b2a2e72d73ca3bd5ef0cc/16:9/w_1920,c_limit/Business-OpenAI-Dall-E-3-heart.jpg"
					}
				]}
				plugins={[Fullscreen]}
			/> */}
			<div>
				{/* {

					images.map((image, i) => (
						<img
							key={i}
							src={image.src}
							alt={image.title}
							onClick={() => setIndex(i)}
							style={{ cursor: 'pointer', width: '100px', height: 'auto' }}
						/>
					))
				}

				{index >= 0 && (
					<Lightbox
						slides={images}
						open={index >= 0}
						index={index}
						onClose={() => setIndex(-1)}
					/>
				)} */}
			</div>
		</div >
	);
}

export default Conversations;