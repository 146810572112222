import React from "react";

const AnimatedProgressBar = () => {
    return (
        <div className="progress rounded-pill mb-2" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', width: '100%' }}>
            <div className="progress-bar bg-indigo progress-bar-striped progress-bar-animated rounded-pill fs-10px fw-bold" style={{ width: '100%' }}>Cargando información</div>
        </div>
    );
}

export default AnimatedProgressBar;