import React from "react";
import PropTypes from 'prop-types';

const StatusBadge = ({ text, status }) => {
    const objBorder = {
        "success": "success",
        "primary": "primary",
        "warning": "warning",
        "yellow": "yellow",
        "danger": "danger",
        "gray": "gray-300"
    }

    const objText = {
        "success": "success",
        "primary": "primary",
        "warning": "warning",
        "yellow": "warning",
        "danger": "danger",
        "gray": "gray-300"
    }

    return (
        <span className={`badge border border-${objBorder[status]} text-${objText[status]} px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center`}><i className="fa fa-circle fs-9px fa-fw me-5px"></i> {text}</span>
    )
}

StatusBadge.propTypes = {
    status: PropTypes.oneOf(['success', 'primary', 'warning', 'yellow', 'danger', 'gray']).isRequired,
};

export default StatusBadge;