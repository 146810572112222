import React from "react";
import PropTypes from 'prop-types';

const Header = ({ header, btnText, handleButton }) => {


    return (<>
        <div className="d-flex align-items-center mb-3">
            <div>
                <ul className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#/">Home</a></li>
                    <li className="breadcrumb-item"><a href="#/">Extra</a></li>
                    <li className="breadcrumb-item active">Orders</li>
                </ul>
                <h1 className="page-header mb-0">{header}</h1>
            </div>
            <div className="ms-auto">
                <button className="btn btn-success btn-rounded px-4 rounded-pill" onClick={handleButton}><i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
                    {btnText}
                </button>
            </div>
        </div>
    </>
    )
}
Header.propTypes = {
    header: PropTypes.string.isRequired,
};

export default Header;