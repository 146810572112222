import React from "react";
import { v4 as uuidv4 } from 'uuid';

const Tabs = ({ tabs, activeTab, onTabChange }) => {

    const activeTabStyle = (item) => activeTab === item && 'active';

    return (
        <ul className="nav nav-tabs nav-tabs-v2 px-3">
            {
                (Array.isArray(tabs) && tabs.length > 0) &&
                tabs.map(item => (
                    <li className="nav-item me-2" key={uuidv4()}>
                        <span className={`nav-link px-2 cursor-pointer ${activeTabStyle(item)}`} onClick={() => onTabChange(item)} >{item}</span>
                    </li>
                ))
            }
        </ul>
    )
}

export default Tabs;