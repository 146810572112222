import EmojiPicker from 'emoji-picker-react';
import { useEffect, useRef, useState } from 'react';
import "./index.css";
import { Icon } from '@iconify/react/dist/iconify.js';
function EmojiPickerComponent({ onEmojiSelect }) {
    const [showPicker, setShowPicker] = useState(false);
    const emojiRef = useRef(null);

    const handleEmojiClick = (emojiObject) => {
        onEmojiSelect(emojiObject);
        setShowPicker(false);
    };

    const handleOpen = () => {
        setShowPicker(true);
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                emojiRef.current &&
                !emojiRef.current.contains(event.target)
            ) {
                setShowPicker(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [emojiRef]);

    return (
        <div onClick={handleOpen} ref={emojiRef}>
            <Icon className="iconify fs-26px" icon="solar:smile-circle-outline" />
            {showPicker &&
                <div className='emoji-picker'>
                    <EmojiPicker onEmojiClick={handleEmojiClick} lazyLoadEmojis={true} />
                </div>
            }
        </div>
    );
}

export default EmojiPickerComponent