import React, { memo, forwardRef, useImperativeHandle } from "react";
import './styles.css';
import { getToken } from "../../helpers/token";
import { urlAPIMultiAgent } from "../../helpers/urlAPI";
import fetchAPI from "../../helpers/fetch";

const MenuTree = forwardRef(
    ({ items, setItems, suscriptionId, setDirectoryId }, ref) => {
        const formatToTree = (data) => {
            const idMap = {};
            const tree = { children: [] };

            data.forEach(item => {
                idMap[item.id] = {
                    id: item.id,
                    name: item.name,
                    parentDirectory: item.parentDirectory ? { id: item.parentDirectory.id } : null,
                    children: [],
                    isOpened: false,
                    isFile: false
                };
            });

            data.forEach(item => {
                const parentId = item.parentDirectory?.id;

                if (!idMap[parentId]) {
                    idMap[parentId] = {
                        id: parentId,
                        name: item.parentDirectory?.name,
                        parentDirectory: { id: parentId },
                        children: [],
                        isOpened: false,
                        isFile: false
                    };
                    tree.children.push(idMap[parentId]);
                }

                if (idMap[parentId]) {
                    idMap[parentId].children.push({
                        id: item.id,
                        name: item.name,
                        directories: item.directories,
                        files: item.files,
                        isOpened: false,
                        isFile: false
                    });
                }
            });

            return tree;
        }

        const formatRootFiles = (data, directoryId) => {
            const rootFolders = data
                .filter(item => item.directory.id === directoryId)
                .map(item => ({
                    id: item.id,
                    name: item.name,
                    parentDirectory: { id: directoryId },
                    isOpened: false,
                    isFile: true
                }));

            return rootFolders;
        }

        const loadSubfolders = (data, folderId) => {

            const updateTree = (folders) => {
                return folders.map(folder => {
                    if (folder.id === folderId) {
                        return {
                            ...folder,
                            children: data?.children[0]?.children,
                            isOpened: !folder.isOpened,
                        };
                    }


                    if (folder.children && folder.children.length > 0) {
                        return { ...folder, children: updateTree(folder.children) };
                    }
                    return folder;
                });
            };

            setItems(prevTree => ({
                ...prevTree,
                children: updateTree(prevTree.children)
            }));
        };

        const EmpyItem = () => (
            <button className='menu-item-tree is-empty'>Sin Carpetas</button>
        );

        const Item = ({ item }) => {
            const handleClick = async () => {

                const headers = {
                    'Authorization': `Bearer ${getToken()}`
                };

                const bodyRequest = {
                    directoryId: item.id
                };

                const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/directory/list`, 'POST', bodyRequest, headers);
                const formattedTree = formatToTree(result.data);
                const resultFiles = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/file/list`, 'POST', bodyRequest, headers);
                const filesTree = {
                    children:
                    {
                        children: [
                            {
                                children: formatRootFiles(resultFiles.data, item.id)
                            }
                        ],
                        id: 0,
                        isOpened: false,
                        name: "Archivos",
                        parentDirectory: item.id
                    }
                };



                const infoJoin = {
                    children: [
                        {
                            id: formattedTree?.children[0]?.id,
                            name: formattedTree?.children[0]?.name,
                            parentDirectory: formattedTree?.children[0]?.parentDirectory,
                            children: [
                                ...(formattedTree?.children[0]?.children || []),
                                ...(filesTree?.children.children[0]?.children || [])
                            ],
                            isOpened: formattedTree?.children[0]?.isOpened
                        }
                    ]
                };

                loadSubfolders(infoJoin, item.id);
                setItems(prevTree => ({
                    ...prevTree,
                    children: prevTree.children.map(folder =>
                        folder.id === item.id ? { ...folder, /* isOpened: !folder.isOpened */ } : folder
                    )
                }));

            };
            // Exponer la función al padre
            useImperativeHandle(ref, () => ({
                handleClick
            }));
            return (
                <div>
                    <button className='menu-item-tree' onClick={async (e) => {
                        e.preventDefault();
                        if (!item.isFile) {
                            await handleClick();
                            setDirectoryId(item.id)
                        }
                    }}>
                        <div className="file-manager-container">
                            <div className="file-manager-sidebar-content">
                                <div className="file-tree">
                                    {
                                        (item.isFile)
                                            ?
                                            //Archivos
                                            <div className="file-node">
                                                <span className="file-link align-items-baseline">
                                                    <span className="file-info">
                                                        <span className="file-icon"><i className="fa fa-file-text fa-lg text-body text-opacity-50"></i></span>
                                                        <span className="file-text text-start">{item.name}</span>
                                                    </span>
                                                </span>
                                            </div>
                                            :
                                            //Carpetas
                                            <div className="file-node has-sub">
                                                <span className="file-link align-items-baseline">
                                                    <span>{item.isOpened ? <i className="fas fa-circle-minus"></i> : <i className="fas fa-circle-plus"></i>}</span>
                                                    <span className="file-info">
                                                        <span className="file-icon"><i className="fa fa-folder fa-lg text-warning"></i></span>
                                                        <span className="file-text text-start">{item.name}</span>
                                                    </span>
                                                </span>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </button>

                    {item.isOpened && (
                        <div className={`sub-menu`}>
                            {
                                (item?.children?.length > 0 && !item.isFile)
                                    ?
                                    item.children.map((subitem) => (
                                        <Item key={subitem.id} item={subitem} />
                                    ))
                                    :
                                    <EmpyItem />
                            }
                        </div>
                    )}
                </div>
            );
        };

        return (
            <>
                {Array.isArray(items.children) && items.children.length > 0 &&
                    items.children.map((item) => (
                        <Item key={item.id} item={item} />
                    ))
                }
            </>
        );
    });

export default memo(MenuTree);