import React from "react";

const FilterSearch = ({ setFilter }) => {

    const handleSearch = (e) => {
        const { value } = e.target;
        setFilter(value);
    }
    return (
        <div className="input-group mb-3">
            <button className="btn btn-white dropdown-toggle" type="button" data-bs-toggle="dropdown"><span className="d-none d-md-inline">Filter orders</span><span className="d-inline d-md-none"><i className="fa fa-credit-card"></i></span> <b className="caret"></b></button>
            <div className="dropdown-menu">
                <a href="#/" className="dropdown-item">Action</a>
                <a href="#/" className="dropdown-item">Another action</a>
                <a href="#/" className="dropdown-item">Something else here</a>
                <div role="separator" className="dropdown-divider"></div>
                <a href="#/" className="dropdown-item">Separated link</a>
            </div>
            <div className="flex-fill position-relative">
                <div className="input-group">
                    <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{ zIndex: 10 }}>
                        <i className="fa fa-search opacity-5"></i>
                    </div>
                    <input type="text" className="form-control px-35px bg-light" placeholder="Buscar..." onChange={handleSearch} />
                </div>
            </div>
        </div>
    )
}

export default FilterSearch;