const Menu = [
  // {
  //   path: '/user', icon: 'fa fa-key', title: 'Login & Register',
  //   children: [
  //     { path: '/user/login-v1', title: 'Login' },
  //     { path: '/user/login-v2', title: 'Login v2' },
  //     { path: '/user/login-v3', title: 'Login v3' },
  //     { path: '/user/register-v3', title: 'Register v3' }
  //   ]
  // },
  {
    path: '/configuracion', icon: 'fa fa-gears', title: 'Configuración',
    children: [
      { path: '/', title: 'Settings Page' }
    ]
  },
  {
    path: '/catalogo', icon: 'fa fa-folder', title: 'Catalogos',
    children: [
      { path: '/catalogo/agentes', title: 'Agentes' },
      { path: '/catalogo/clientes', title: 'Clientes' },
      { path: '/catalogo/usuarios', title: 'Usuarios' },
      { path: '/catalogo/suscripciones', title: 'Suscripciones' },
      { path: '/catalogo/administradores', title: 'Administradores' },
      { path: '/catalogo/archivos', title: 'Archivos' },
    ]
  },
  { path: '/conversaciones', icon: 'fa fa-message', title: 'Conversaciones' },
  // {
  //   path: '/precios', icon: 'fa fa-sack-dollar', title: 'Precios',
  //   children: [
  //     { path: '/precios', title: 'Precios' }
  //   ]
  // },
  {
    path: '/helper', icon: 'fa fa-medkit', title: 'Helper',
    children: [
      { path: '/helper/css', title: 'Predefined CSS Classes' }
    ]
  },
]

export default Menu;