import React, { useEffect, useContext, useState, useRef } from 'react';
import { AppSettings } from './../../config/app-settings.js';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Icon } from '@iconify/react';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { getToken } from '../../helpers/token.js';
import fetchAPI from '../../helpers/fetch.js';
import { urlAPIMultiAgent } from '../../helpers/urlAPI.js';
import { getLocalStorageItem } from '../../helpers/localStorage.js';
import AnimatedProgressBar from '../../ownComponents/progressBar/animated.js';
import { v4 as uuidv4 } from 'uuid';
import { Modal as ModalFileManager } from 'bootstrap';
import Modal from '../../ownComponents/modal/modal.js';
import Alert, { addNotification } from '../../ownComponents/alerts/alerts.js';
import { handleErrorFormat } from '../../helpers/handleError.js';
import "./styles.css"
import MenuTreeNew from './ViewTree.js';
function FileManager() {
    const { suscriptionId, suscriptionCode } = JSON.parse(getLocalStorageItem("suscription"));
    const token = getToken();
    const context = useContext(AppSettings);
    const [isMobileSidebarToggled, setIsMobileSidebarToggled] = useState(false);
    // const [dataDirectories, setDataDirectories] = useState([]);
    const [dataFiles, setDataFiles] = useState([]);
    // const [dataFilesTree, setDataFilesTree] = useState([]);
    const [dataRefresh, setDataRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [directoryId, setDirectoryId] = useState(0);//revisar que antes estaba como cero
    const [subdirectories, setSubdirectories] = useState([]);
    const [directoryName, setDirectoryName] = useState("");
    const [directoryIdUpdate, setDirectoryIdUpdate] = useState(0);
    const [modal, setModal] = useState();
    const [modalDelete, setModalDelete] = useState();
    const [modalFile, setModalFile] = useState();
    const [modalDeleteFile, setModalDeleteFile] = useState();
    const [dataFile, setDataFile] = useState([]);
    const [fileId, setFileId] = useState(0);
    const [formatTree, setFormatTree] = useState({});
    const [dataSubscription, setDataSubscription] = useState({});

    //navegación
    const [currentDirectoryID, setCurrentDirectoryID] = useState(directoryId); //aqui iba null originalmente
    const [history, setHistory] = useState([]);
    const [forwardHistory, setForwardHistory] = useState([]);

    const navigateToDirectory = (directoryID) => {
        // Actualizar el historial
        if (currentDirectoryID !== null) {
            setHistory([...history, currentDirectoryID]);
        }
        setCurrentDirectoryID(directoryID);
        setDirectoryId(directoryID)
        setForwardHistory([]);
    };

    const goBack = () => {
        if (history.length > 0) {
            const previousDirectoryID = history[history.length - 1];
            setForwardHistory([currentDirectoryID, ...forwardHistory]);
            setCurrentDirectoryID(previousDirectoryID);
            setDirectoryId(previousDirectoryID);
            setHistory(history.slice(0, -1));
        }
    };

    const goForward = () => {
        if (forwardHistory.length > 0) {
            const nextDirectoryID = forwardHistory[0];
            setHistory([...history, currentDirectoryID]); // Agregar al historial actual
            setCurrentDirectoryID(nextDirectoryID);
            setDirectoryId(nextDirectoryID)
            setForwardHistory(forwardHistory.slice(1)); // Eliminar el primer elemento del historial hacia adelante
        }
    };

    const fileInputRef = useRef(null);

    const handleMobileSidebarToggle = () => {
        setIsMobileSidebarToggled(!isMobileSidebarToggled);
    };

    const handleRefresh = () => {
        setDataRefresh(dataRefresh => !dataRefresh);
    }

    const handleChangeDirectory = (id) => {
        setDirectoryId(id);
    }

    const handleFileChange = async (e) => {
        await ftFetchFileUpload(e.target.files[0]);
        handleRefresh();
    }

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleOpenModal = async () => {
        modal.show();
    }

    const handleCloseModal = () => {
        modal.hide();
        setDirectoryName("");
        setDirectoryIdUpdate(0);
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const result = directoryIdUpdate !== 0 ? await ftFetchUpdateDirectory(directoryIdUpdate) : await ftFetchCreateDirectory();
            handleRefresh();
            handleCloseModal();
            addNotification({
                notificationTitle: "¡Exito!",
                notificationMessage: result.message,
                notificationPosition: 'top-center',
                notificationType: 'success'
            })
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }
    }

    const handleSubmitFile = async (e) => {
        try {
            e.preventDefault();
            const result = await ftFetchUpdateFile(dataFile.id);
            handleRefresh();
            modalFile.hide();
            addNotification({
                notificationTitle: "¡Exito!",
                notificationMessage: result.message,
                notificationPosition: 'top-center',
                notificationType: 'success'
            })
            setDirectoryName("");
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }
    }

    const handleDeleteDirectory = async () => {
        try {

            const result = await ftFetchDeleteDirectory();
            handleRefresh();
            addNotification({
                notificationTitle: "¡Exito!",
                notificationMessage: result.message,
                notificationPosition: 'top-center',
                notificationType: 'success'
            })
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }
    }

    const handleDeleteFile = async () => {
        try {

            const result = await ftFetchDeleteFile();
            handleRefresh();
            addNotification({
                notificationTitle: "¡Exito!",
                notificationMessage: result.message,
                notificationPosition: 'top-center',
                notificationType: 'success'
            })
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }
    }



    const ftFetchFileUpload = async (file) => {

        const formData = new FormData()

        formData.append('file', file);
        formData.append('directoryId', directoryId);


        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const result = await fetch(`${urlAPIMultiAgent}/suscription/${suscriptionId}/file/create`, { body: formData, method: 'POST', headers });
        if (result.ok) {
            return await result.json();
        } else {
            throw new Error('Error en la carga del archivo');
        }
    }

    const ftFetchGetDirectory = async (id) => {
        const bodyRequest = {
            directoryId: id,
        };

        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/directory/${id}/get`, 'POST', bodyRequest, headers);
        setDirectoryName(result.data.name);
    }

    const ftFetchDeleteDirectory = async () => {

        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/directory/${directoryIdUpdate}/remove`, 'POST', null, headers);
        return result;
    }

    const ftFetchUpdateDirectory = async (id) => {
        const bodyRequest = {
            name: directoryName
        };

        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/directory/${id}/update`, 'POST', bodyRequest, headers);
        setDirectoryName(result.data.name);
        return result;
    }

    const ftFetchUpdateFile = async (id) => {
        const bodyRequest = {
            name: dataFile.name,
            description: dataFile.description
        };

        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/file/${id}/update`, 'POST', bodyRequest, headers);
        setDirectoryName(result.data.name);
        return result;
    }

    const ftFetchCreateDirectory = async () => {
        setIsLoading(true);
        const bodyRequest = {
            directoryId: directoryId,
            name: directoryName
        };

        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/directory/create`, 'POST', bodyRequest, headers);
        setIsLoading(false);
        return result;
    }

    const ftFetchDeleteFile = async () => {

        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/file/${fileId}/remove`, 'POST', null, headers);
        return result;
    }

    const ftFetchGetFile = async (id) => {
        const bodyRequest = {
            directoryId: id,
        };

        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/file/${id}/get`, 'POST', bodyRequest, headers);
        setDataFile(result.data);
    }

    const ftDownloadFile = async (urlAPI, fileName) => {
        const response = await fetch(urlAPI);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const childRef = useRef();

    const handleClickChild = () => {
        if (childRef.current) {
            childRef.current.handleClick(); // Llama a la función del hijo
        }
    };

    const bytesToMB = (bytes) => {
        if (bytes === 0) return '0 MB';
        const mb = bytes / (1024 * 1024);
        return `${mb.toFixed(2)} MB`;
    }

    const calculateStoragePercentages = (allocatedStorage, usedStorage, availableStorage) => {
        const percentageUsed = (usedStorage / allocatedStorage) * 100;
        const percentageAvailable = (availableStorage / allocatedStorage) * 100;

        return {
            percentageUsed: percentageUsed.toFixed(2), // Formato a dos decimales
            percentageAvailable: percentageAvailable.toFixed(2) // Formato a dos decimales
        };
    }

    const ftFetchGetSubscription = async (id) => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`
            }

            const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/get`, 'POST', null, headers);
            const allocatedStorage = bytesToMB(result.data.allocatedStorage);
            const usedStorage = bytesToMB(result.data.usedStorage);
            const availableStorage = bytesToMB(result.data.availableStorage);
            bytesToMB(result.data.allocatedStorage)
            const storagePercentages = calculateStoragePercentages(allocatedStorage.split("MB")[0], usedStorage.split("MB")[0], availableStorage.split("MB")[0]);
            setDataSubscription(
                {
                    ...result.data,
                    allocatedStorage,
                    usedStorage,
                    availableStorage,
                    storagePercentages
                });
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }
    }

    useEffect(() => {
        // context.handleSetAppSidebarMinified(true);
        // context.handleSetAppHeaderInverse(true);
        context.handleSetAppContentFullHeight(true);
        setModal(new ModalFileManager(document.getElementById('fileManager')));
        setModalFile(new ModalFileManager(document.getElementById('file')));
        setModalDelete(new ModalFileManager(document.getElementById('fileManagerDelete')));
        setModalDeleteFile(new ModalFileManager(document.getElementById('fileDelete')));

        return () => {
            // context.handleSetAppSidebarMinified(false);
            // context.handleSetAppHeaderInverse(false);
            context.handleSetAppContentFullHeight(false);
        };

        // eslint-disable-next-line
    }, []);


    function formatRootFolders(data) {
        const rootFolders = data
            .filter(item => item.parentDirectory === null) // Filtrar carpetas raíz
            .map(item => ({
                id: item.id,
                name: item.name,
                parentDirectory: { id: item.id }, // Solo incluir el id en parentDirectory
                children: [], // Inicializar children como un arreglo vacío
                isOpened: false,
                isFile: false
            }));

        return { children: rootFolders }; // Devolver el resultado en la estructura deseada
    }

    function formatRootFiles(data) {
        const auxDirectoryId = directoryId === 0 ? null : directoryId;
        const rootFolders = data
            .filter(item => item.directory === auxDirectoryId) // Filtrar carpetas raíz
            .map(item => ({
                id: item.id,
                name: item.name,
                parentDirectory: { id: directoryId }, // Solo incluir el id en parentDirectory
                isOpened: false
            }));

        return { children: rootFolders }; // Devolver el resultado en la estructura deseada
    }

    // PROBABLEMENTE ESTE USEEFFECT SE PUEDE QUITAR 
    useEffect(() => {

        const fetchDirectories = async () => {
            // setIsLoading(true);
            const headers = {
                'Authorization': `Bearer ${token}`
            }
            const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/directory/list`, 'POST', null, headers);
            const dataFormat = formatRootFolders(result.data);
            setFormatTree(dataFormat);
        }

        fetchDirectories();

        // eslint-disable-next-line
    }, [dataRefresh]);
    /*(el de arriba) PROBABLEMENTE ESTE USEEFFECT SE PUEDE QUITAR */
    useEffect(() => {

        const fetchFilesList = async () => {
            setIsLoading(true);
            const bodyRequest = {
                directoryId: directoryId
            };

            const headers = {
                'Authorization': `Bearer ${token}`
            }
            const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/file/list`, 'POST', bodyRequest, headers);
            setDataFiles(result.data);
            setIsLoading(false);
            await ftFetchGetSubscription();
        }

        fetchFilesList();

        // eslint-disable-next-line
    }, [dataRefresh, directoryId]);

    useEffect(() => {

        const ftFetchDirectoriesList = async () => {

            const headers = {
                'Authorization': `Bearer ${token}`
            }

            const bodyRequest = {
                directoryId
            }
            const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/directory/list`, 'POST', bodyRequest, headers);
            setSubdirectories(result.data);
            // const dataFormatFolders = formatRootFolders(result.data);
            // console.log({ dataFormatFolders, dataFiles });
            // if (dataFiles.length > 0) {
            //     const infoJoin = [...dataFormatFolders.children, ...dataFiles];
            //     console.log({ infoJoin })
            //     const infoTree = {
            //         children: infoJoin


            //     }
            //     console.log({ infoTree })
            //     setFormatTree(infoTree)
            // }
        }

        ftFetchDirectoriesList();

        // eslint-disable-next-line
    }, [dataRefresh, directoryId /*, dataFiles */]);



    const ftRenderIcon = (type) => {
        const IconStyle = {
            "video": "file-video",
            "image": "file-image",
            "document": "file",
            "folder": "folder"
        }
        return <i className={`fas fa-${IconStyle[type]} fa-lg`}></i>
    }

    const ftModalDirectory = () => {

        return <form onSubmit={handleSubmit} className="fs-13px">
            <div className="form-floating mb-15px">
                <input type="text" className="form-control h-45px fs-13px" placeholder="Nombre" id="name" name='name' required value={directoryName} onChange={(e) => setDirectoryName(e.target.value)} pattern="^(?!\s*$).+" />
                <label htmlFor="name" className="d-flex align-items-center fs-13px text-gray-600">Nombre</label>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-default" onClick={handleCloseModal}>Cancelar</button>
                <button type="submit" className="btn btn-theme">Guardar</button>
            </div>
        </form>
    }

    const ftModalDeleteDirectory = () => {

        return <div className="d-flex justify-content-evenly">
            <button type="button" className="btn btn-default" onClick={() => { modalDelete.hide(); setDirectoryIdUpdate(0) }}>Cancelar</button>
            <button type="submit" className="btn btn-danger" onClick={async () => {
                await handleDeleteDirectory();
                modalDelete.hide();
                setDirectoryIdUpdate(0);
            }}>
                Eliminar</button>
        </div>
    }

    const ftModalFile = () => {

        return <form onSubmit={handleSubmitFile} className="fs-13px">
            <div className="form-floating mb-15px">
                <input type="text" className="form-control h-45px fs-13px" placeholder="Nombre" id="name" name='name' required value={dataFile.name} onChange={(e) => setDataFile(dataFile => ({ ...dataFile, name: e.target.value }))} pattern="^(?!\s*$).+" />
                <label htmlFor="name" className="d-flex align-items-center fs-13px text-gray-600">Nombre</label>
            </div>
            <div className="form-floating mb-15px">
                <input type="text" className="form-control h-45px fs-13px" placeholder="Nombre" id="description" name='description' required value={dataFile.description || ""} onChange={(e) => setDataFile(dataFile => ({ ...dataFile, description: e.target.value }))} pattern="^(?!\s*$).+" />
                <label htmlFor="name" className="d-flex align-items-center fs-13px text-gray-600">Descripción</label>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-default" onClick={() => { modalFile.hide(); setDataFile([]); }}>Cancelar</button>
                <button type="submit" className="btn btn-theme">Guardar</button>
            </div>
        </form>
    }

    const ftModalDeleteFile = () => {

        return <div className="d-flex justify-content-evenly">
            <button type="button" className="btn btn-default" onClick={() => { modalDeleteFile.hide(); setFileId(0) }}>Cancelar</button>
            <button type="submit" className="btn btn-danger" onClick={async () => {
                await handleDeleteFile();
                modalDeleteFile.hide();
            }}>
                Eliminar</button>
        </div>
    }

    return (
        <div className="h-100 d-flex flex-column">
            <div>
                <ol className="breadcrumb float-xl-end">
                    <li className="breadcrumb-item"><a href="#/">Home</a></li>
                    <li className="breadcrumb-item"><a href="#/">Extra</a></li>
                    <li className="breadcrumb-item active">File Manager</li>
                </ol>
                <h1 className="page-header">Listado de archivos</h1>
            </div>

            <Panel className="flex-1 m-0 d-flex flex-column overflow-hidden">
                <PanelHeader>Archivos</PanelHeader>
                <PanelBody className="p-0 flex-1 overflow-hidden">
                    <div className={'file-manager h-100 ' + (isMobileSidebarToggled ? 'file-manager-sidebar-mobile-toggled' : '')}>
                        <div className="file-manager-toolbar">
                            {/* <button type="button" className="btn shadow-none text-body border-0"><i className="fa fa-lg me-1 fa-plus"></i> Archivo</button> */}
                            <button type="button" className="btn shadow-none text-body border-0" onClick={handleOpenModal}><i className="fa fa-lg me-1 fa-plus"></i> Carpeta</button>
                            {/* <button type="button" className="btn shadow-none text-body text-opacity-50 border-0" disabled><i className="fa fa-lg me-1 fa-copy"></i> Copy</button> */}
                            {/* <button type="button" className="btn shadow-none text-body text-opacity-50 border-0" disabled><i className="fa fa-lg me-1 fa-move"></i> Move</button> */}
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                className="file-input"
                                accept="*/*" // Puedes especificar tipos de archivos si es necesario
                                style={{ display: 'none' }}
                            />
                            <button type="button" className="btn shadow-none text-body border-0" onClick={handleButtonClick}><i className="fa fa-lg me-1 fa-upload"></i> Cargar</button>
                            {/* <button type="button" className="btn shadow-none text-body border-0"><i className="fa fa-lg me-1 fa-download"></i> Descargar</button> */}
                            {/* <button type="button" className="btn shadow-none text-body border-0"><i className="fa fa-lg me-1 fa-xmark"></i> Eliminar</button> */}
                            {/* <button type="button" className="btn shadow-none text-body text-opacity-50 border-0" disabled><i className="fa fa-lg me-1 fa-rotate-left"></i> Restore</button> */}
                            {/* <button type="button" className="btn shadow-none text-body text-opacity-50 border-0" disabled><i className="fa fa-lg me-1 fa-file"></i> Rename</button> */}
                            {/* <button type="button" className="btn shadow-none text-body text-opacity-50 border-0" disabled><i className="fa fa-lg me-1 fa-pen"></i> Edit</button> */}
                            {/* <button type="button" className="btn shadow-none text-body text-opacity-50 border-0" disabled><i className="fa fa-lg me-1 fa-pen-to-square"></i> HTML Editor</button> */}
                            {/* <button type="button" className="btn shadow-none text-body text-opacity-50 border-0" disabled><i className="fa fa-lg me-1 fa-key"></i> Permissions</button> */}
                            {/* <button type="button" className="btn shadow-none text-body text-opacity-50 border-0" disabled><i className="fa fa-lg me-1 fa-file"></i> View</button> */}
                            {/* <button type="button" className="btn shadow-none text-body text-opacity-50 border-0" disabled><i className="fa fa-lg me-1 fa-lock-open"></i> Extract</button> */}
                            {/* <button type="button" className="btn shadow-none text-body text-opacity-50 border-0" disabled><i className="fa fa-lg me-1 fa-file-zipper"></i> Compress</button> */}
                        </div>
                        <div className="file-manager-container">
                            <div className="file-manager-sidebar">
                                <div className="file-manager-sidebar-mobile-toggler">
                                    <button type="button" className="btn" onClick={handleMobileSidebarToggle}><i className="far fa-lg fa-folder"></i></button>
                                </div>
                                <div className="file-manager-sidebar-content">
                                    <PerfectScrollbar className="h-100 p-3" options={{ suppressScrollX: true }}>
                                        <input type="text" className="form-control form-control-sm mb-3" placeholder="Seach file..." />
                                        {/* <div className="file-tree mb-3">
                                            <div className="file-node has-sub expand selected">
                                                <span className="file-link cursor-pointer" onClick={() => handleChangeDirectory(0)}>
                                                    <span className="file-arrow"></span>
                                                    <span className="file-info">
                                                        <span className="file-icon"><i className="fa fa-folder fa-lg text-warning"></i></span>
                                                        <span className="file-text">archivos</span>
                                                    </span>
                                                </span>
                                             
                                            </div>
                                        </div> */}
                                        <MenuTreeNew setItems={setFormatTree} items={formatTree} suscriptionId={suscriptionId} setDirectoryId={setDirectoryId} ref={childRef} />
                                    </PerfectScrollbar>
                                </div>
                                <div className="file-manager-sidebar-footer">
                                    <div className="d-flex align-items-center">
                                        <div className="mx-n1">
                                            <Icon className="iconify fa-3x" icon="solar:ssd-square-bold-duotone" />
                                        </div>
                                        <div className="flex-1 ps-3 small">
                                            <div className="fw-bold">Almacenamiento:</div>
                                            <div className="progress h-5px my-1">
                                                <div className="progress-bar progress-bar-striped bg-inverse" style={{ width: `${dataSubscription?.storagePercentages?.percentageUsed}%` }}></div>
                                            </div>
                                            <div className="fw-bold text-body text-opacity-75">
                                                <b className="text-body">{dataSubscription.availableStorage}</b> disponible de <b className="text-body">{dataSubscription.allocatedStorage}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="file-manager-content d-flex flex-column">
                                <div className="mb-0 d-flex text-nowrap p-3 border-bottom">
                                    <button type="button" className="btn btn-sm btn-white me-2 px-2" onClick={() => setDirectoryId(0)}><i className="fa fa-fw fa-home"></i></button>
                                    {/* <button type="button" className="btn btn-sm btn-white me-2" disabled><i className="fa fa-fw fa-arrow-turn-up ms-n1"></i>  Up One Level</button> */}

                                    <div className="btn-group me-2">
                                        <button type="button" className="btn btn-sm btn-white" onClick={goBack} disabled={history.length === 0}><i className="fa me-1 fa-arrow-left"></i> Back</button>
                                        <button type="button" className="btn btn-sm btn-white text-opacity-50" onClick={goForward} disabled={forwardHistory.length === 0}><i className="fa me-1 fa-arrow-right"></i> Forward</button>
                                    </div>
                                    <button type="button" className="btn btn-sm btn-white me-2 px-2" onClick={handleRefresh}><i className="fa fa-fw fa-arrows-rotate"></i></button>

                                    {/* <div className="btn-group me-2">
                                        <button type="button" className="btn btn-sm btn-white"><i className="fa fa-fw fa-check ms-n1"></i> Select All</button>
                                        <button type="button" className="btn btn-sm btn-white"><i className="far fa-fw fa-square ms-n1"></i> Unselect All</button>
                                    </div> */}
                                </div>
                                <div className="flex-1 overflow-hidden">
                                    <PerfectScrollbar className="h-100 p-0" options={{ suppressScrollX: true }}>
                                        {
                                            isLoading ?
                                                <AnimatedProgressBar />
                                                :
                                                <table className="table table-striped table-borderless table-sm m-0 text-nowrap">
                                                    <thead>
                                                        <tr className="border-bottom">
                                                            <th className="w-10px ps-10px"></th>
                                                            <th className="px-10px">Nombre</th>
                                                            <th className="px-10px w-100px">Tamaño</th>
                                                            <th className="px-10px w-200px">Tipo</th>
                                                            <th className="px-10px w-200px">Acciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            Array.isArray(subdirectories) && subdirectories.length > 0 &&
                                                            subdirectories.map(item => (
                                                                <tr key={uuidv4()}>
                                                                    <td className="ps-10px border-0 text-center cursor-pointer" onClick={() => {
                                                                        // handleChangeDirectory(item.id); 
                                                                        navigateToDirectory(item.id)
                                                                    }}>
                                                                        {
                                                                            ftRenderIcon("folder")
                                                                        }

                                                                    </td>
                                                                    <td className="px-10px border-0 cursor-pointer" onClick={() => {
                                                                        // handleChangeDirectory(item.id);
                                                                        // handleClickChild();
                                                                        navigateToDirectory(item.id)
                                                                    }}>
                                                                        {item.name}
                                                                    </td>
                                                                    <td className="ps-10px border-0">
                                                                        {/* {item.size} */}
                                                                    </td>
                                                                    <td className="ps-10px border-0">
                                                                        folder
                                                                    </td>
                                                                    <td>
                                                                        <div className="btn-group me-1 mb-1 ">
                                                                            <button className="btn btn-default" data-bs-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></button>
                                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                                <button className="dropdown-item" onClick={async () => {
                                                                                    handleOpenModal()
                                                                                    setDirectoryIdUpdate(item.id);
                                                                                    await ftFetchGetDirectory(item.id);
                                                                                }
                                                                                }
                                                                                >
                                                                                    <i className="fas fa-pencil-alt fa-fw pe-4"></i>
                                                                                    Editar
                                                                                </button>
                                                                                <div className="dropdown-divider"></div>
                                                                                <button className="dropdown-item" onClick={() => { modalDelete.show(); setDirectoryIdUpdate(item.id) }}>
                                                                                    <i className="fas fa-trash-can fa-fw pe-4"></i>
                                                                                    Eliminar
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        {
                                                            Array.isArray(dataFiles) && dataFiles.length > 0 &&
                                                            dataFiles.map(item => (
                                                                <tr key={uuidv4()}>
                                                                    <td className="ps-10px border-0 text-center">
                                                                        {
                                                                            ftRenderIcon(item.type)
                                                                        }
                                                                    </td>
                                                                    <td className="px-10px border-0">
                                                                        <span className='cursor-pointer text-decoration-underline text-blue' onClick={() => ftDownloadFile(`https://apimultiagent.customersolutions.click/api/v1/file/${suscriptionCode}/${item.fileName}`, item.name)}>{item.name}</span>
                                                                    </td>
                                                                    <td className="ps-10px border-0">
                                                                        {item.size}
                                                                    </td>
                                                                    <td className="ps-10px border-0">
                                                                        {item.type}
                                                                    </td>
                                                                    <td>
                                                                        <div className="btn-group me-1 mb-1 ">
                                                                            <button className="btn btn-default" data-bs-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></button>
                                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                                <button className="dropdown-item" onClick={async () => {
                                                                                    modalFile.show();
                                                                                    setFileId(item.id);
                                                                                    await ftFetchGetFile(item.id);
                                                                                }
                                                                                }>
                                                                                    <i className="fas fa-pencil-alt fa-fw pe-4"></i>
                                                                                    Editar
                                                                                </button>
                                                                                <div className="dropdown-divider"></div>
                                                                                <button className="dropdown-item" onClick={() => { modalDeleteFile.show(); setFileId(item.id) }}>
                                                                                    <i className="fas fa-trash-can fa-fw pe-4"></i>
                                                                                    Eliminar
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }

                                                    </tbody>
                                                </table>
                                        }
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </PanelBody>
            </Panel>
            <Modal body={ftModalDirectory()} header={`${directoryIdUpdate === 0 ? "Crear" : "Renombrar"} carpeta`} idModal="fileManager" handleCloseModal={handleCloseModal} />
            <Modal body={ftModalDeleteDirectory()} header={"¿Desea eliminar la carpeta y todo su contenido?"} idModal="fileManagerDelete" handleCloseModal={() => modalDelete.hide()} />
            <Modal body={ftModalFile()} header={`Editar archivo`} idModal="file" handleCloseModal={() => modalFile.hide()} />
            <Modal body={ftModalDeleteFile()} header={"¿Desea eliminar el archivo?"} idModal="fileDelete" handleCloseModal={() => modalDelete.hide()} />
            <Alert />

        </div>
    );
}

export default FileManager;