import React, { useEffect, useState } from "react";
import Modal from '../../ownComponents/modal/modal.js';
import { getToken } from "../../helpers/token.js";
import fetchAPI from "../../helpers/fetch.js";
import { urlAPIMultiAgent } from "../../helpers/urlAPI.js";
import { v4 as uuidv4 } from 'uuid';

const ModalFiles = ({ suscriptionId, modalFiles, handleSendFile }) => {

    const [dataDirectories, setDataDirectories] = useState([]);
    const [dataFiles, setDataFiles] = useState([]);
    const [directoryId, setDirectoryId] = useState(0);
    //navegación
    const [currentDirectoryID, setCurrentDirectoryID] = useState(directoryId);
    const [history, setHistory] = useState([]);
    const [forwardHistory, setForwardHistory] = useState([]);

    const navigateToDirectory = (directoryID) => {
        // Actualizar el historial
        if (currentDirectoryID !== null) {
            setHistory([...history, currentDirectoryID]);
        }
        setCurrentDirectoryID(directoryID);
        setDirectoryId(directoryID)
        setForwardHistory([]);
    };

    const goBack = () => {
        if (history.length > 0) {
            const previousDirectoryID = history[history.length - 1];
            setForwardHistory([currentDirectoryID, ...forwardHistory]);
            setCurrentDirectoryID(previousDirectoryID);
            setDirectoryId(previousDirectoryID);
            setHistory(history.slice(0, -1));
        }
    };

    const goForward = () => {
        if (forwardHistory.length > 0) {
            const nextDirectoryID = forwardHistory[0];
            setHistory([...history, currentDirectoryID]); // Agregar al historial actual
            setCurrentDirectoryID(nextDirectoryID);
            setDirectoryId(nextDirectoryID)
            setForwardHistory(forwardHistory.slice(1)); // Eliminar el primer elemento del historial hacia adelante
        }
    };


    const handleCloseModalFiles = () => {
        modalFiles.hide()
    }
    const ftRenderIcon = (type) => {
        const IconStyle = {
            "video": "file-video",
            "image": "file-image",
            "document": "file",
            "folder": "folder"
        }
        return <i className={`fas fa-${IconStyle[type]} fa-lg`}></i>
    }



    useEffect(() => {

        const ftFetchDirectoriesList = async () => {

            const headers = {
                'Authorization': `Bearer ${getToken()}`
            }

            const bodyRequest = {
                directoryId
            }
            const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/directory/list`, 'POST', bodyRequest, headers);
            setDataDirectories(result.data);
        }

        ftFetchDirectoriesList();

        // eslint-disable-next-line
    }, [directoryId]);

    useEffect(() => {

        const ftFetchFilesList = async () => {

            const headers = {
                'Authorization': `Bearer ${getToken()}`
            }

            const bodyRequest = {
                directoryId
            }
            const result = await fetchAPI(`${urlAPIMultiAgent}/suscription/${suscriptionId}/file/list`, 'POST', bodyRequest, headers);
            setDataFiles(result.data);
        }

        ftFetchFilesList();

        // eslint-disable-next-line
    }, [directoryId]);

    const ftModalFiles = () => {

        return <>
            <div className="btn-group me-2">
                <button type="button" className="btn btn-sm btn-white me-2 px-2" onClick={() => setDirectoryId(0)} disabled={directoryId === 0}><i className="fa fa-fw fa-home"></i></button>
                <button type="button" className="btn btn-sm btn-white" onClick={goBack} disabled={history.length === 0}><i className="fa me-1 fa-arrow-left"></i> Back</button>
                <button type="button" className="btn btn-sm btn-white text-opacity-50" onClick={goForward} disabled={forwardHistory.length === 0}><i className="fa me-1 fa-arrow-right"></i> Forward</button>
            </div>



            <table className="table table-striped table-borderless table-sm m-0 text-nowrap">
                <thead>
                    <tr className="border-bottom">
                        <th className="w-10px ps-10px"></th>
                        <th className="px-10px">Nombre</th>
                        <th className="px-10px w-100px">Tamaño</th>
                        <th className="px-10px w-200px">Tipo</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        Array.isArray(dataDirectories) && dataDirectories.length > 0 &&
                        dataDirectories.map(item => (
                            <tr key={uuidv4()}>
                                <td className="ps-10px border-0 text-center cursor-pointer" onClick={() => {
                                    // handleChangeDirectory(item.id);
                                    navigateToDirectory(item.id)
                                    setDirectoryId(item.id)
                                }}>
                                    {
                                        ftRenderIcon("folder")
                                    }

                                </td>
                                <td className="px-10px border-0 cursor-pointer" onClick={() => {
                                    // handleChangeDirectory(item.id);
                                    // handleClickChild();
                                    navigateToDirectory(item.id)
                                    setDirectoryId(item.id)
                                }}>
                                    {item.name}
                                </td>
                                <td className="ps-10px border-0">
                                    {/* {item.size} */}
                                </td>
                                <td className="ps-10px border-0">
                                    folder
                                </td>
                            </tr>
                        ))
                    }
                    {
                        Array.isArray(dataFiles) && dataFiles.length > 0 &&
                        dataFiles.map(item => (
                            <tr key={uuidv4()}>
                                <td className="ps-10px border-0 text-center">
                                    {
                                        ftRenderIcon(item.type)
                                    }
                                </td>
                                <td className="px-10px border-0">
                                    <span className='cursor-pointer text-decoration-underline text-blue' onClick={() => {
                                        handleSendFile(item.id);
                                        modalFiles.hide();
                                    }}>{item.name}</span>
                                </td>
                                <td className="ps-10px border-0">
                                    {item.size}
                                </td>
                                <td className="ps-10px border-0">
                                    {item.type}
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </>
    }

    return (
        <>

            <Modal body={ftModalFiles()} header={`Listado de archivos`} idModal="fileManager" handleCloseModal={handleCloseModalFiles} size="modal-lg" />
        </>
    )
}

export default ModalFiles;