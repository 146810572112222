import React, { useState } from 'react';
import { dateFormat } from '../../helpers/date';
import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import "yet-another-react-lightbox/styles.css";

function Chat({ data, suscriptionCode }) {
    const ftDownloadFile = async (urlAPI, fileName) => {
        const response = await fetch(urlAPI);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const { agent, content, method, deliveredDate, file } = data;
    const isAgent = agent !== null;
    const styleStartEnd = isAgent ? "start" : "end";

    const [openLightBox, setOpenLightBox] = useState(false);
    return (
        <div className={`widget-chat-item with-media ${styleStartEnd}`}>
            <div className="widget-chat-media">
                <img alt="" src={"https://cdn-icons-png.flaticon.com/512/17/17004.png"} />
            </div>
            <div className="widget-chat-info">
                <div className="widget-chat-info-container">
                    <div className={`widget-chat-name ${isAgent ? 'text-blue' : 'text-black'}`}>{isAgent ? "Agente:" : "Cliente:"}</div>
                    <div className="widget-chat-message"> {content} </div>
                    {
                        (data.file && method === "image") &&
                        <img className='cursor-pointer' src={`https://apimultiagent.customersolutions.click/api/v1/file/${suscriptionCode}/${file.fileName}`} alt="imagen" width={200} onClick={() => setOpenLightBox(true)} />
                    }
                    {
                        (data.file && method === "document") &&
                        <span className='cursor-pointer text-decoration-underline text-blue' onClick={() => ftDownloadFile(`https://apimultiagent.customersolutions.click/api/v1/file/${suscriptionCode}/${file.fileName}`, file.name)}>{file.name}</span>
                    }
                    {
                        (data.file && method === "video") &&
                        <video width="200" controls>
                            <source src={`https://apimultiagent.customersolutions.click/api/v1/file/${suscriptionCode}/${file.fileName}`} type={file.contentType} />
                            Tu navegador no soporta la etiqueta de video.
                        </video>
                    }
                    <div className="widget-chat-time">{dateFormat(deliveredDate)}</div>
                </div>
            </div>
            <Lightbox
                open={openLightBox}
                close={() => setOpenLightBox(false)}
                slides={[
                    {
                        src: `https://apimultiagent.customersolutions.click/api/v1/file/${suscriptionCode}/${file?.fileName}`
                    }
                ]}
                plugins={[Fullscreen, Video, Zoom]}
            />
        </div>
    )
}

export default Chat;

