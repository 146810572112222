import React, { useEffect, useState } from "react";
import fetchAPI from "../../helpers/fetch";
import { urlAPIMultiAgent } from "../../helpers/urlAPI";
import { getToken } from "../../helpers/token";
import TableUser from "./table";
import Header from "../../ownComponents/table/header";
import Actions from "../../ownComponents/table/actions";
import Tabs from "../../ownComponents/table/tabs";
import FilterSearch from "../../ownComponents/table/filterSearch";
import Pagination from "../../ownComponents/table/pagination";
import Modal from "../../ownComponents/modal/modal";
import Alert, { addNotification } from "../../ownComponents/alerts/alerts"
import { handleErrorFormat } from "../../helpers/handleError";
import { Modal as ModalUser } from 'bootstrap';

function Users() {

    const dataUserIni = {
        id: 0,
        username: "",
        password: "",
        name: "",
        email: "",
        phone: "",
        roles: "ROLE_USER",
        suscriptions: ""
    }
    const token = getToken();


    const tableHeaders =
        [
            { header: "Id" },
            { header: "Usuario" },
            { header: "Nombre" },
            { header: "Correo" },
            { header: "Teléfono" },
            { header: "Roles" },
            { header: "Suscripciones" },
            { header: "Acciones" },
        ];
    const tableTabs = [

    ]
    const [dataUsers, setDataUsers] = useState([]);
    console.log({ dataUsers })
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = Array.isArray(dataUsers) && dataUsers.length > 0 ? dataUsers.slice(indexOfFirstEntry, indexOfLastEntry) : 0;
    const [filter, setFilter] = useState('');
    const [dataUserForm, setDataUserForm] = useState(dataUserIni);
    const [dataUsersFilter, setDataUsersFilter] = useState([]);
    const [totalEntries, setTotalEntries] = useState(0);
    const [activeTab, setActiveTab] = useState('Disponible');
    const [reloadData, setReloadData] = useState(false);
    const [modal, setModal] = useState();
    const [modalDelete, setModalDelete] = useState();


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab); // Cambiar la pestaña activa
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setDataUserForm((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleGet = async (id) => {
        await ftFetchGet(id);
        modal.show();
    }


    const handleDelete = async (id) => {
        modalDelete.show();
        setDataUserForm({ ...dataUserForm, id })
    }

    const handleCloseModal = () => {
        modal.hide();
        setDataUserForm(dataUserIni);
    }

    const handleBtnHeader = () => {
        modal.show();
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const result = dataUserForm.id === 0 ? await ftFetchInsert() : await ftFetchEdit(dataUserForm.id);
            setReloadData(reloadData => !reloadData);
            handleCloseModal();
            addNotification({
                notificationTitle: "¡Exito!",
                notificationMessage: result.message,
                notificationPosition: 'top-center',
                notificationType: 'success'
            })
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }

    }
    const ftFetchGet = async (id) => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`
            }

            const result = await fetchAPI(`${urlAPIMultiAgent}/user/${id}/get`, 'POST', null, headers);
            setDataUserForm({ ...result.data, password: "" });
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }
    }

    const ftFetchDelete = async () => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`
            }

            const result = await fetchAPI(`${urlAPIMultiAgent}/user/${dataUserForm.id}/remove`, 'POST', null, headers);
            addNotification({
                notificationTitle: "¡Exito!",
                notificationMessage: result.message,
                notificationPosition: 'top-center',
                notificationType: 'success'
            });
            modalDelete.hide();
            setDataUserForm(dataUserIni);
        } catch (error) {
            addNotification({
                notificationTitle: "Ha ocurrido un error",
                notificationMessage: handleErrorFormat(error),
                notificationPosition: 'top-center',
                notificationType: 'warning'
            });
        }
    }

    const ftFetchEdit = async (id) => {
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const bodyFetch = {
            username: dataUserForm.username,
            password: dataUserForm.password,
            name: dataUserForm.name,
            email: dataUserForm.email,
            phone: dataUserForm.phone,
            roles: dataUserForm.roles,
            suscriptions: ""
        }
        const result = await fetchAPI(`${urlAPIMultiAgent}/user/${id}/update`, 'POST', bodyFetch, headers);
        return result;
    }

    const ftFetchInsert = async () => {
        const headers = {
            'Authorization': `Bearer ${token}`
        }
        const bodyFetch = {
            username: dataUserForm.username,
            password: dataUserForm.password,
            name: dataUserForm.name,
            email: dataUserForm.email,
            phone: dataUserForm.phone,
            roles: dataUserForm.roles,
            suscriptions: ""
        }
        const result = await fetchAPI(`${urlAPIMultiAgent}/user/create`, 'POST', bodyFetch, headers);
        return result;
    }



    useEffect(() => {
        setModal(new ModalUser(document.getElementById('user')));
        setModalDelete(new ModalUser(document.getElementById('userDelete')));
        return () => {
            setModal();
            setModalDelete();
        }
    }, [])

    useEffect(() => {

        const fetchData = async () => {
            try {
                const headers = {
                    'Authorization': `Bearer ${token}`
                }
                const result = await fetchAPI(`${urlAPIMultiAgent}/user/list`, 'POST', null, headers);
                setDataUsers(result.data);
                setDataUsersFilter(result.data);
                setTotalEntries(result.data.length);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
        // return () => {

        // };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadData]);

    useEffect(() => {
        const filteredData = dataUsersFilter.filter(
            item => item.name !== null ? item.name.toLowerCase().includes(filter.toLowerCase()) : item);
        setDataUsers(filteredData);

        const totalEntries = filteredData.length;
        setTotalEntries(totalEntries);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);
    const totalPages = Math.ceil(totalEntries / entriesPerPage);

    // Ajustar la página actual si es necesario
    useEffect(() => {
        if (currentPage > totalPages) {
            setCurrentPage(totalPages > 0 ? totalPages : 1);
        }
    }, [totalEntries, currentPage, totalPages]);


    const ftModalForm = () => {

        return <form onSubmit={handleSubmit} className="fs-13px">
            <div className="form-floating mb-15px">
                <input type="text" className="form-control h-45px fs-13px" placeholder="Nombre" id="username" name='username' required value={dataUserForm.username} onChange={(e) => handleChange(e)} pattern="^(?!\s*$).+" />
                <label htmlFor="username" className="d-flex align-items-center fs-13px text-gray-600">Usuario</label>
            </div>
            <div className="form-floating mb-15px">
                <input type="text" className="form-control h-45px fs-13px" placeholder="Nombre" id="name" name='name' required value={dataUserForm.name} onChange={(e) => handleChange(e)} pattern="^(?!\s*$).+" />
                <label htmlFor="name" className="d-flex align-items-center fs-13px text-gray-600">Nombre Completo</label>
            </div>
            <div className="form-floating mb-15px">
                <input type="text" className="form-control h-45px fs-13px" placeholder="Email Address" id="email" name='email' required value={dataUserForm.email} onChange={(e) => handleChange(e)} pattern="[^ ]+" title="Campo requerido" />
                <label htmlFor="email" className="d-flex align-items-center fs-13px text-gray-600">Correo</label>
            </div>
            <div className="form-floating mb-15px">
                <input type="password" className="form-control h-45px fs-13px" placeholder="Password" id="password" name='password' required={dataUserForm.id === 0} value={dataUserForm.password} onChange={(e) => handleChange(e)} />
                <label htmlFor="password" className="d-flex align-items-center fs-13px text-gray-600">Contraseña</label>
            </div>
            <div className="form-floating mb-15px">
                <input type="text" className="form-control h-45px fs-13px" placeholder="Teléfono" id="phone" name='phone' required value={dataUserForm.phone} onChange={(e) => handleChange(e)} pattern="^(?!\s*$).+" />
                <label htmlFor="phone" className="d-flex align-items-center fs-13px text-gray-600">Teléfono</label>
            </div>
            <div className="form-floating mb-15px">
                <select name="roles" className="form-select" value={dataUserForm.roles} onChange={handleChange}>
                    <option value={"ROLE_USER"}>Usuario</option>
                    <option value={"ROLE_ADMIN"}>Administrador</option>
                </select>
                <label htmlFor="roles" className="d-flex align-items-center fs-13px text-gray-600">Rol</label>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-default" onClick={handleCloseModal}>Cancelar</button>
                <button type="submit" className="btn btn-theme">Guardar</button>
            </div>
        </form>
    }
    const ftModalDelete = () => {

        return <div className="d-flex justify-content-evenly">
            <button type="button" className="btn btn-default" onClick={() => modalDelete.hide()}>Cancelar</button>
            <button type="submit" className="btn btn-danger" onClick={async () => {
                await ftFetchDelete();
                setReloadData(reloadData => !reloadData);
            }}>Eliminar</button>
        </div>
    }

    return (
        <>
            <Modal body={ftModalForm()} header={`${dataUserForm.id === 0 ? 'Agregar' : 'Editar'} Usuario`} idModal="user" handleCloseModal={handleCloseModal} />
            <Modal body={ftModalDelete()} header={"¿Desea eliminar el usuario?"} idModal="userDelete" handleCloseModal={() => modalDelete.hide()} />
            <Header btnText={"Agregar"} header={"Usuarios"} handleButton={handleBtnHeader} />
            <Actions />
            <div className="card border-0">
                <Tabs tabs={tableTabs} activeTab={activeTab} onTabChange={handleTabChange} />
                <div className="tab-content p-3">
                    <div className="tab-pane fade show active" id="allTab">
                        <FilterSearch setFilter={setFilter} />
                        <div className="table-responsive mb-3">
                            <TableUser headers={tableHeaders} body={currentEntries} handleGet={handleGet} handleDelete={handleDelete} />
                        </div>
                        <Pagination currentPage={currentPage} entriesPerPage={entriesPerPage} onPageChange={handlePageChange} totalEntries={totalEntries} setEntriesPerPage={setEntriesPerPage} totalPages={totalPages} />
                    </div>
                </div>
            </div>

            <Alert />
        </>
    );
}

export default Users;